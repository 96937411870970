// this file origin from Winningtemp.Shared

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Resources from "Resources";
import ContentWrapper from "Controls/ContentWrapper";
import QuestionTalkModalHeader from "./QuestionTalkModalHeader";
import QuestionDoYouWantToTalkScreen from "./QuestionDoYouWantToTalkScreen";
import QuestionCommentDescriptionMobile from "../QuestionCommentDescriptionMobile";
import QuestionSelectContactScreen from "./QuestionSelectContactScreen";
import QuestionSelectContextScreen from "./QuestionSelectContextScreen";
import QuestionDescribeDetailsScreen from "./QuestionDescribeDetailsScreen";

const SCREENS = {
	doYouWantToTalk: 0,
	selectContact: 1,
	selectContext: 2,
	describeDetails: 3,
};

class QuestionTalkExtended extends PureComponent {
	constructor(props) {
		super(props);

		this.config = this.getConfig();

		this.state = {
			isCommentDescriptionActive: false,
			activeScreen: SCREENS.doYouWantToTalk,
			progress: 0,
			selectedDetailOption: this.props.detailsOptions[0],
			selectedContextOption: this.props.contextOptions[0],
			selectedContact: this.props.contacts[0],
			escalateTalkExtendedData: {
				contactKey: this.props.contacts[0].Key,
				comment: "",
			},
			questionTalkExperienceData: {
				details: this.props.detailsOptions.length ? this.props.detailsOptions[0].Id : null,
				context: this.props.contextOptions[0].Id,
				comment: this.props.question ? this.props.question.comment : "",
				visibleForImmediateManager:
					!!this.props.question && this.props.question.visibleForImmediateManager,
				allowAiAssistedReplies: !!this.props.question && this.props.question.allowAiAssistedReplies,
			},
		};
	}

	onExperienceVisibleForImmediateManagerChange = () => {
		this.setState({
			questionTalkExperienceData: {
				...this.state.questionTalkExperienceData,
				visibleForImmediateManager: !this.state.questionTalkExperienceData.visibleForImmediateManager,
			},
		});
	};

	onAllowAiAssistedRepliesChange = () => {
		this.setState({
			questionTalkExperienceData: {
				...this.state.questionTalkExperienceData,
				allowAiAssistedReplies: !this.state.questionTalkExperienceData.allowAiAssistedReplies,
			},
		});
	};

	onExperienceCommentChange = (comment) => {
		this.setState({
			questionTalkExperienceData: {
				...this.state.questionTalkExperienceData,
				comment,
			},
		});
	};

	onExperienceDetailOptionChange = (item) => {
		this.setState({
			selectedDetailOption: item,
			questionTalkExperienceData: {
				...this.state.questionTalkExperienceData,
				details: item.Id,
			},
		});
	};

	onExperienceContextOptionChange = (item) => {
		this.setState({
			selectedContextOption: item,
			questionTalkExperienceData: {
				...this.state.questionTalkExperienceData,
				context: item.Id,
			},
		});
	};

	onEscalateTalkCommentChange = (comment) => {
		this.setState({
			escalateTalkExtendedData: {
				...this.state.escalateTalkExtendedData,
				comment,
			},
		});
	};

	onEscalateTalkContactChange = (contact) => {
		this.setState({
			selectedContact: contact,
			escalateTalkExtendedData: {
				...this.state.escalateTalkExtendedData,
				contactKey: contact.Key,
			},
		});
	};

	onCommentSend = () => {
		const talkModel = {
			SupportUserId: this.state.escalateTalkExtendedData.contactKey,
			Comment: this.state.escalateTalkExtendedData.comment,
		};
		this.props.onCommentSend(talkModel);
	};

	onExperienceSend = () => {
		const answer = {
			...this.props.question,
			comment: this.state.questionTalkExperienceData.comment,
			visibleForImmediateManager: this.state.questionTalkExperienceData.visibleForImmediateManager,
			allowAiAssistedReplies: this.state.questionTalkExperienceData.allowAiAssistedReplies,
			additionalInformation: {
				additionalInformationStrategy: this.props.question.additionalInformationStrategy,
				context: this.state.questionTalkExperienceData.context,
				details: this.state.questionTalkExperienceData.details,
			},
		};
		this.props.onExperienceSend(answer);
	};

	getConfig = () => {
		return {
			[SCREENS.doYouWantToTalk]: {
				render: this.renderDoYouWantToTalkScreen,
			},
			[SCREENS.selectContact]: {
				render: this.renderSelectContactScreen,
			},
			[SCREENS.selectContext]: {
				render: this.renderSelectContextScreen,
			},
			[SCREENS.describeDetails]: {
				render: this.renderDescribeDetailsScreen,
			},
		};
	};

	openDoYouWantToTalkScreen = () => {
		this.props.getOutterProgressBarWidth && this.props.getOutterProgressBarWidth(0);
		this.setState({
			activeScreen: SCREENS.doYouWantToTalk,
			progress: 0,
		});
	};

	openSelectContactScreen = () => {
		this.props.getOutterProgressBarWidth && this.props.getOutterProgressBarWidth(50);
		this.setState({
			activeScreen: SCREENS.selectContact,
			progress: 50,
		});
	};

	openSelectContextScreen = () => {
		this.props.getOutterProgressBarWidth && this.props.getOutterProgressBarWidth(33);
		this.setState({
			activeScreen: SCREENS.selectContext,
			progress: 33,
		});
	};

	openDescribeDetailsScreen = () => {
		this.props.getOutterProgressBarWidth && this.props.getOutterProgressBarWidth(66);
		this.setState({
			activeScreen: SCREENS.describeDetails,
			progress: 66,
		});
	};

	commentDescriptionToggle = () => {
		this.setState({ isCommentDescriptionActive: !this.state.isCommentDescriptionActive });
	};

	renderDoYouWantToTalkScreen = () => {
		return (
			<QuestionDoYouWantToTalkScreen
				culture={this.props.culture}
				onNoClick={this.openSelectContextScreen}
				onYesClick={this.openSelectContactScreen}
				question={this.props.question}
			/>
		);
	};

	renderSelectContactScreen = () => {
		return (
			<QuestionSelectContactScreen
				isExternalContact={this.state.selectedContact.Key === "External"}
				culture={this.props.culture}
				contacts={this.props.contacts}
				text={Resources[this.props.culture].contactDesiredPerson}
				comment={this.state.escalateTalkExtendedData.comment}
				onCommentChange={this.onEscalateTalkCommentChange}
				selectedContact={this.state.selectedContact}
				onContactChange={this.onEscalateTalkContactChange}
				onSendClick={this.onCommentSend}
				onUndoClick={this.openDoYouWantToTalkScreen}
			/>
		);
	};

	renderSelectContextScreen = () => {
		return (
			<QuestionSelectContextScreen
				culture={this.props.culture}
				questionSettings={this.props.questionSettings}
				onContextOptionChange={this.onExperienceContextOptionChange}
				onDetailOptionChange={this.onExperienceDetailOptionChange}
				detailsOptions={this.props.detailsOptions}
				contextOptions={this.props.contextOptions}
				selectedDetailOption={this.state.selectedDetailOption}
				selectedContextOption={this.state.selectedContextOption}
				onNextClick={this.openDescribeDetailsScreen}
				onSendClick={this.onExperienceSend}
				question={this.props.question}
			/>
		);
	};

	renderDescribeDetailsScreen = () => {
		return (
			<QuestionDescribeDetailsScreen
				culture={this.props.culture}
				question={this.props.question}
				questionSettings={this.props.questionSettings}
				onCommentChange={this.onExperienceCommentChange}
				allowAiAssistedReplies={this.state.questionTalkExperienceData.allowAiAssistedReplies}
				onAllowAiAssistedRepliesChange={this.onAllowAiAssistedRepliesChange}
				isVisibleForImmediateManager={
					this.state.questionTalkExperienceData.visibleForImmediateManager
				}
				onVisibleForImmediateManagerChange={this.onExperienceVisibleForImmediateManagerChange}
				comment={this.state.questionTalkExperienceData.comment}
				onSendClick={this.onExperienceSend}
				commentDescriptionToggle={this.commentDescriptionToggle}
				onBackClick={this.openSelectContextScreen}
			/>
		);
	};

	render() {
		if (!this.config[this.state.activeScreen]) {
			return null;
		}

		return (
			<ContentWrapper>
				{this.state.isCommentDescriptionActive && (
					<QuestionCommentDescriptionMobile
						culture={this.props.culture}
						onClose={this.commentDescriptionToggle}
					/>
				)}
				{this.props.withHeader && (
					<QuestionTalkModalHeader
						titleText={Resources[this.props.culture].pleaseElaborateOnYourResponse}
						progressWidth={this.state.progress}
					/>
				)}
				{this.config[this.state.activeScreen].render()}
			</ContentWrapper>
		);
	}
}

QuestionTalkExtended.propTypes = {
	withHeader: PropTypes.bool,
	culture: PropTypes.string,
	question: PropTypes.object,
	questionSettings: PropTypes.object,
	detailsOptions: PropTypes.array,
	contextOptions: PropTypes.array,
	contacts: PropTypes.array,
	getOutterProgressBarWidth: PropTypes.func,
	onCommentSend: PropTypes.func,
	onExperienceSend: PropTypes.func,
};

export default QuestionTalkExtended;
