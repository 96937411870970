import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Colors from "Colors";
import ContentWrapper from "Controls/ContentWrapper";
import SurveyContainerWrapper from "./Question/SurveyContainerWrapper";
import HeaderWithProfile from "./HeaderWithProfile";
import { QuestionProgressBar } from "./Question";
import { QuestionTalkExtended } from "./Question/QuestionTalk";

const Wrapper = styled.div`
	background: ${Colors.questionWindowBackground};
	max-width: 1024px;
	margin-left: auto;
	margin-right: auto;
	padding-top: 30px;
	@media (max-width: 1024px) {
		min-height: 99vh;
		padding-top: 30px;
		padding-top: calc(30px + env(safe-area-inset-bottom));
		padding-bottom: 60px;
		padding-bottom: calc(60px + env(safe-area-inset-bottom));
	}
	@media (max-width: 425px) {
		padding-top: calc(30px + env(safe-area-inset-bottom));
		padding-bottom: calc(118px + env(safe-area-inset-bottom));
	}
`;

class EscalateTalkExtended extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			progress: 0,
		};
	}

	getOutterProgressBarWidth = (progress) => {
		this.setState({
			progress,
		});
	};

	render() {
		return (
			<SurveyContainerWrapper className="with-auto-height-adjust">
				<ContentWrapper display="block" position="fixed" top="0" width="100%" zIndex="1">
					<HeaderWithProfile
						padding="20px 30px"
						paddingMedia="20px"
						username={this.props.username}
						culture={this.props.culture}
						onLogout={this.props.onLogout}
					/>
					<QuestionProgressBar progressWidth={this.state.progress} />
				</ContentWrapper>
				<Wrapper>
					<QuestionTalkExtended
						culture={this.props.culture}
						question={this.props.question}
						questionSettings={this.props.questionSettings}
						urls={this.props.urls}
						detailsOptions={this.props.detailsOptions}
						contextOptions={this.props.contextOptions}
						contacts={this.props.contacts}
						getOutterProgressBarWidth={this.getOutterProgressBarWidth}
						onCommentSend={this.props.onCommentSend}
						onExperienceSend={this.props.onExperienceSend}
					/>
				</Wrapper>
			</SurveyContainerWrapper>
		);
	}
}

EscalateTalkExtended.propTypes = {
	culture: PropTypes.string,
	question: PropTypes.object,
	questionSettings: PropTypes.object,
	detailsOptions: PropTypes.array,
	contextOptions: PropTypes.array,
	contacts: PropTypes.array,
	onCommentSend: PropTypes.func,
	onExperienceSend: PropTypes.func,
};

export default EscalateTalkExtended;
