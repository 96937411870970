export const ESCALATE_TEMPERATURE_ID = 0;
export const ESCALATE_TALK_ID = 1;
export const ESCALATE_TALK_EXTENDED_ID = 2;

export const ESCALATE_TEMPERATURE_URLS = {
    save: "escalate/comment"   
};

export const ESCALATE_TALK_URLS = {
    submitDefault: "escalate/questionTalk/default",
    submitExternal: "escalate/questionTalk/external"
};