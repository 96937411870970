import { QuestionAdditionalStrategy } from "Enums";

export default (answers) => {
	return answers.find(answer => {
			const isNegativeBullying = answer.additionalInformationStrategy === QuestionAdditionalStrategy.negativeBullying;
			const isNegativeAnswer = answer.grade < 2;
			return !answer.skipReason && isNegativeBullying && isNegativeAnswer;
		})
		|| answers.find(answer => {
			const isNegativeDiscrimination = answer.additionalInformationStrategy === QuestionAdditionalStrategy.negativeDiscrimination;
			const isNegativeAnswer = answer.grade < 2;
			return !answer.skipReason && isNegativeDiscrimination && isNegativeAnswer;
		});
}