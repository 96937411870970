// this file origin from Winningtemp.Shared

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Resources from "Resources";
import ContentWrapper from "Controls/ContentWrapper";
import QuestionTalkHOC from "./QuestionTalkHOC";
import QuestionTalkModalHeader from "./QuestionTalkModalHeader";
import QuestionDoYouWantToTalkScreen from "./QuestionDoYouWantToTalkScreen";
import QuestionSelectContactScreen from "./QuestionSelectContactScreen";

class QuestionTalk extends PureComponent {
	constructor(props) {
		super(props);

		this.config = this.getConfig();
	}

	getConfig = () => {
		return {
			[this.props.screens.doYouWantToTalk]: {
				render: this.renderDoYouWantToTalkScreen,
			},
			[this.props.screens.selectContact]: {
				render: this.renderSelectContactScreen,
			},
		};
	};

	renderDoYouWantToTalkScreen = () => {
		return (
			<QuestionDoYouWantToTalkScreen
				culture={this.props.culture}
				doYouWantToTalkDescription={
					Resources[this.props.culture].doYouNeedSupportAndWantToTalkToSomeone
				}
				doYouWantToTalkQuestion={
					Resources[this.props.culture].doYouWantToTalkToSomeoneAboutYourWorkSituation
				}
				nextButtonAttribute="escalation-strategy-health-step-1-yes"
				backButtonAttribute="escalation-strategy-health-step-1-no"
				onNoClick={this.props.onQuestionTalkNoSubmit}
				onYesClick={this.props.openSelectContactScreen}
			/>
		);
	};

	renderSelectContactScreen = () => {
		const isExternalContact = this.props.selected === "External";
		let text = isExternalContact
				? Resources[this.props.culture].questionTalkExternalDescription
				: Resources[this.props.culture].questionTalkDefaultDescription;
		text += `<br/><br/>${Resources[this.props.culture].contactDesiredPerson}`;

		return (
			<QuestionSelectContactScreen
				isExternalContact={isExternalContact}
				culture={this.props.culture}
				nextButtonAttribute="escalation-strategy-health-step-2-submit"
				backButtonAttribute="escalation-strategy-health-step-2-back"
				contacts={this.props.contacts}
				text={text}
				comment={this.props.questionTalkData.comment}
				onCommentChange={this.props.onCommentChange}
				selectedContact={this.props.selectedContact}
				onContactChange={this.props.onContactChange}
				onSendClick={this.props.onSubmit}
				onUndoClick={this.props.openDoYouWantToTalkScreen}
			/>
		);
	};

	render() {
		if (!this.config[this.props.activeScreen]) {
			return null;
		}

		return (
			<ContentWrapper>
				<QuestionTalkModalHeader
					titleText={Resources[this.props.culture].doYouWantToTalkToSomeone}
					progressWidth={this.props.progress}
				/>
				{this.config[this.props.activeScreen].render()}
			</ContentWrapper>
		);
	}
}

QuestionTalk.propTypes = {
	culture: PropTypes.string,
	token: PropTypes.string,
	urls: PropTypes.object,
	contacts: PropTypes.array,
	onQuestionTalkSubmit: PropTypes.func,
	onQuestionTalkNoSubmit: PropTypes.func,
};

export default QuestionTalkHOC(QuestionTalk);
